import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { ServiceStatus } from '../views';
import i18n from '../i18n';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(ServiceStatus)
  .use(pinia)
  .use(i18n)
  .mount('#service-status-overlay');
